import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/customizer.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { PublicRoutes, RequireAuth } from "./components/AuthChecker";
import { socket } from "./context/sockets";
import { useSelector } from "react-redux";
import { selectUser } from "./features/auth/authSlice";

const Home = lazy(() => import("./pages/Home"));
const MyCompanies = lazy(() =>
  import("./pages/Companies/MyCompanies/MyCompanies")
);
const AllCompanies = lazy(() => import("./pages/Companies/AllCompanies"));
const AddAllCompanies = lazy(() => import("./pages/Companies/AddAllCompanies"));
const AddMyCompany = lazy(() =>
  import("./pages/Companies/MyCompanies/AddMyCompany")
);
const ViewAllCompanies = lazy(() =>
  import("./pages/Companies/ViewAllCompanies")
);
const ViewCompany = lazy(() => import("./pages/Companies/ViewCompany"));

const SaleCompanies = lazy(() =>
  import("./pages/Companies/SaleCompanies/SaleCompanies")
);
const AddSaleCompanies = lazy(() =>
  import("./pages/Companies/SaleCompanies/AddSaleCompanies")
);

const PurchaseCompanies = lazy(() =>
  import("./pages/Companies/PurchaseCompanies/PurchaseCompanies")
);
const AddPurchaseCompanies = lazy(() =>
  import("./pages/Companies/PurchaseCompanies/AddPurchaseCompanies")
);
const NoPage = lazy(() => import("./pages/Authentication/NoPage"));
const Layout = lazy(() => import("./components/Layout"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const UserProfile = lazy(() => import("./pages/UserProfile"));
const SuggestionsFeedbacks = lazy(() => import("./pages/SuggestionsFeedbacks"));
const SignIn = lazy(() => import("./pages/Authentication/SignIn"));
const AllNotifications = lazy(() => import("./pages/AllNotifications"));
const AllMessages = lazy(() => import("./pages/AllMessages"));
const Analytics = lazy(() => import("./pages/Analytics"));
const MasterData = lazy(() => import("./pages/MasterData"));
const Tutorials = lazy(() => import("./pages/Tutorials"));
const PlanPricing = lazy(() => import("./pages/PlanPricing"));
const OrderVia = lazy(() => import("./pages/Complaints/OrderVia/OrderVia"));
const CreateOrderVia = lazy(() =>
  import("./pages/Complaints/OrderVia/CreateOrderVia")
);

const CreateComplaint = lazy(() =>
  import("./pages/Complaints/CreateComplaint")
);
const RequestsComplaint = lazy(() =>
  import("./pages/Complaints/RequestsComplaint")
);
const ViewRequestsComplaint = lazy(() =>
  import("./pages/Complaints/ViewRequestsComplaint")
);
const Allocate = lazy(() => import("./pages/Complaints/Allocate"));
const StockRequest = lazy(() =>
  import("./pages/Complaints/StockRequest/StockRequest")
);
const CreateStockRequest = lazy(() =>
  import("./pages/Complaints/StockRequest/CreateStockRequest")
);
const StockPunch = lazy(() => import("./pages/Complaints/StockPunch"));
const CreateStockPunch = lazy(() =>
  import("./pages/Complaints/CreateStockPunch")
);
const ExpensePunch = lazy(() => import("./pages/Complaints/ExpensePunch"));
const CreateExpensePunch = lazy(() =>
  import("./pages/Complaints/CreateExpensePunch")
);
const ApprovedComplaints = lazy(() =>
  import("./pages/Complaints/ApprovedComplaints")
);
const RejectedComplaints = lazy(() =>
  import("./pages/Complaints/RejectedComplaints")
);
const ResolvedComplaints = lazy(() =>
  import("./pages/Complaints/ResolvedComplaints")
);
const CreateAllocate = lazy(() => import("./pages/Complaints/CreateAllocate"));
const WorkQuotations = lazy(() =>
  import("./pages/WorkQuotations/WorkQuotations")
);
const CreateQuotation = lazy(() =>
  import("./pages/WorkQuotations/CreateQuotation")
);
const SendWorkQuotationByEmail = lazy(() =>
  import("./pages/WorkQuotations/SendWorkQuotationByEmail")
);
const PoViewDetails = lazy(() => import("./pages/PurchaseOrder/PoViewDetails"));
const PurchaseOrder = lazy(() => import("./pages/PurchaseOrder/PurchaseOrder"));
const CreateSecurityDeposit = lazy(() =>
  import("./pages/PurchaseOrder/CreateSecurityDeposit")
);
const CreateBillingType = lazy(() =>
  import("./pages/PurchaseOrder/CreateBillingType")
);
const CreatePurchaseOrder = lazy(() =>
  import("./pages/PurchaseOrder/CreatePurchaseOrder")
);
// const BillingType = lazy(() => import("./pages/Billings/BillingType"));
const Taxes = lazy(() => import("./pages/Billings/Taxes"));
const CreateTaxes = lazy(() => import("./pages/Billings/CreateTaxes"));
const FinancialYear = lazy(() => import("./pages/Billings/FinancialYear"));
const CreateFinancialYear = lazy(() =>
  import("./pages/Billings/CreateFinancialYear")
);
const CreateMeasurement = lazy(() =>
  import("./pages/Billings/CreateMeasurement")
);
const ViewMeasurement = lazy(() => import("./pages/Billings/ViewMeasurement"));
const Measurements = lazy(() => import("./pages/Billings/Measurements"));
const MergetoInvoice = lazy(() => import("./pages/Billings/MergetoInvoice"));
const PiToMerge = lazy(() => import("./pages/Billings/PiToMerge"));
const Merge = lazy(() => import("./pages/Billings/Merge"));
const MergedPi = lazy(() => import("./pages/Billings/MergedPi"));
const Payments = lazy(() => import("./pages/Billings/Payments"));
const CreateNewPayments = lazy(() =>
  import("./components/ModalContent/CreateNewPayments")
);
const PerformaInvoice = lazy(() => import("./pages/Billings/PerformaInvoice"));
const CreatePerformaInvoice = lazy(() =>
  import("./pages/Billings/CreatePerformaInvoice")
);
const Invoice = lazy(() => import("./pages/Billings/Invoice"));
const CreateInvoice = lazy(() => import("./pages/Billings/CreateInvoice"));
const Retention = lazy(() => import("./pages/Billings/Retention"));
const CreateRetention = lazy(() =>
  import("./components/ModalContent/CreateRetention")
);
const ProductCategory = lazy(() =>
  import("./pages/ProductService/ProductCategory")
);
const CreateProductCategory = lazy(() =>
  import("./pages/ProductService/CreateProductCategory")
);
const UnitData = lazy(() => import("./pages/ProductService/UnitData"));
const CreateUnitData = lazy(() =>
  import("./pages/ProductService/CreateUnitData")
);
const AddProducts = lazy(() => import("./pages/ProductService/AddProducts"));
const ProductService = lazy(() =>
  import("./pages/ProductService/ProductService")
);
const EarthingTesting = lazy(() =>
  import("./pages/EarthingTesting/EarthingTesting")
);
const CreateEarthingTesting = lazy(() =>
  import("./pages/EarthingTesting/CreateEarthingTesting")
);
const AllSurvey = lazy(() => import("./pages/Survey/AllSurvey"));
const PurposeMaster = lazy(() => import("./pages/Survey/PurposeMaster"));
const CreatePurposeMaster = lazy(() =>
  import("./pages/Survey/CreatePurposeMaster")
);
const SurveyItemMaster = lazy(() => import("./pages/Survey/SurveyItemMaster"));
const CreateSurveyItemMaster = lazy(() =>
  import("./pages/Survey/CreateSurveyItemMaster")
);
const CreateSurvey = lazy(() => import("./pages/Survey/CreateSurvey"));
const ViewSurvey = lazy(() => import("./pages/Survey/ViewSurvey"));
const ResponseSurvey = lazy(() => import("./pages/Survey/ResponseSurvey"));
const ViewResponseSurvey = lazy(() =>
  import("./pages/Survey/ViewResponseSurvey")
);
const AssignedSurvey = lazy(() => import("./pages/Survey/AssignedSurvey"));
const RequestSurvey = lazy(() => import("./pages/Survey/RequestSurvey"));
const OfficeSiteStocks = lazy(() =>
  import("./pages/OfficeInspection/SiteStocks")
);
const OfficeExpense = lazy(() =>
  import("./pages/OfficeInspection/expense/OfficeExpense")
);
const FoodExpensePunch = lazy(() =>
  import("./pages/OfficeInspection/expense/FoodExpensePunch")
);
const OfficeComplaintsOnOutlet = lazy(() =>
  import("./pages/OfficeInspection/expense/OfficeComplaintsOnOutlet")
);
const OfficeExpensePendingComplaints = lazy(() =>
  import("./pages/OfficeInspection/expense/OfficeExpensePendingComplaints")
);
const ComplaintsOnOutlet = lazy(() =>
  import("./pages/OfficeInspection/ComplaintsOnOutlet")
);
const PendingComplaints = lazy(() =>
  import("./pages/OfficeInspection/PendingComplaints")
);
const ViewPendingComplaints = lazy(() =>
  import("./pages/OfficeInspection/ViewPendingComplaints")
);
const SiteExpense = lazy(() => import("./pages/SiteInspection/SiteExpense"));
const SiteStocks = lazy(() => import("./pages/SiteInspection/SiteStocks"));
const WorkImages = lazy(() => import("./pages/WorkImages/WorkImages"));
const CreateWorkImages = lazy(() =>
  import("./pages/WorkImages/CreateWorkImages")
);
const ViewWorkImages = lazy(() => import("./pages/WorkImages/ViewWorkImages"));
const ItemMaster = lazy(() => import("./pages/ItemMaster"));
const AddItemMaster = lazy(() =>
  import("./components/ModalContent/AddItemMaster")
);
const Stock = lazy(() => import("./pages/Stocks/Stock"));
const StockTransfer = lazy(() => import("./pages/Stocks/StockTransfer"));
const ViewStock = lazy(() => import("./pages/Stocks/ViewStock"));
const AddStock = lazy(() => import("./components/ModalContent/AddStock"));
const Vendor = lazy(() => import("./pages/Vendor"));
const Suppliers = lazy(() => import("./pages/Suppliers"));
const AddSuppliers = lazy(() =>
  import("./components/ModalContent/AddSuppliers")
);
const AllAssets = lazy(() => import("./pages/AssetManagement/AllAssets"));
const CreateAssets = lazy(() => import("./pages/AssetManagement/CreateAssets"));
const IdleAssets = lazy(() => import("./pages/AssetManagement/IdleAssets"));
const AssetsRepairRequire = lazy(() =>
  import("./pages/AssetManagement/AssetsRepairRequire")
);
const AssignedAssets = lazy(() =>
  import("./pages/AssetManagement/AssignedAssets")
);
const IndividualAssetProfile = lazy(() =>
  import("./pages/AssetManagement/IndividualAssetProfile")
);
const AssetTimeline = lazy(() =>
  import("./pages/AssetManagement/AssetTimeline")
);
const TimelineAssignedAssets = lazy(() =>
  import("./pages/AssetManagement/TimelineAssignedAssets")
);
const Contacts = lazy(() => import("./pages/Contacts/Contacts"));
const CreateContacts = lazy(() => import("./pages/Contacts/CreateContacts"));
const DocumentsLists = lazy(() => import("./pages/Documents/DocumentsLists"));
const DocumentCategoryView = lazy(() =>
  import("./pages/Documents/DocumentCategoryView")
);
const DocumentCategory = lazy(() =>
  import("./pages/Documents/DocumentCategory")
);
const CreateDocumentCategory = lazy(() =>
  import("./pages/Documents/CreateDocumentCategory")
);
const AddDocument = lazy(() => import("./pages/Documents/AddDocument"));
const Communication = lazy(() => import("./pages/Communication"));
const Reports = lazy(() => import("./pages/Reports"));
const EnergyCompanyTeam = lazy(() => import("./pages/EnergyCompanyTeam"));
const TaskDashboard = lazy(() => import("./pages/TaskManager/TaskDashboard"));
const TaskCategory = lazy(() => import("./pages/TaskManager/TaskCategory"));
const TaskView = lazy(() => import("./pages/TaskManager/TaskView"));
const AllTask = lazy(() => import("./pages/TaskManager/AllTask"));
const OutletManagement = lazy(() => import("./pages/OutletManagement"));
const CreateOutletManagement = lazy(() =>
  import("./components/ModalContent/CreateOutletManagement")
);
const DesignationPermissions = lazy(() =>
  import("./pages/HRManagement/DesignationPermissions")
);
// const AllRoles = lazy(() => import('./pages/AllRoles'))
const Teams = lazy(() => import("./pages/HRManagement/Teams/Teams"));
const CreateTeams = lazy(() =>
  import("./pages/HRManagement/Teams/CreateTeams")
);
const ViewTeamLevelWise = lazy(() =>
  import("./pages/HRManagement/Teams/ViewTeamLevelWise")
);
const HrTeamMembers = lazy(() => import("./pages/HRManagement/HrTeamMembers"));
const Employees = lazy(() => import("./pages/HRManagement/Employees"));
const AddEmployee = lazy(() => import("./components/ModalContent/AddEmployee"));
const ViewEmployee = lazy(() =>
  import("./pages/HRManagement/ViewEmployee/ViewEmployee")
);
const Attendance = lazy(() => import("./pages/HRManagement/Attendance"));
const UserAttendance = lazy(() =>
  import("./pages/HRManagement/ViewTimeCardsAttendance/UserAttendance")
);
const Leaves = lazy(() => import("./pages/HRManagement/Leaves/Leaves"));
const LeavesType = lazy(() => import("./pages/HRManagement/Leaves/LeavesType"));
const ViewEmployeeLeave = lazy(() =>
  import("./pages/HRManagement/ViewEmployee/ViewEmployeeLeave")
);
const Payroll = lazy(() => import("./pages/HRManagement/Payroll/Payroll"));
const PayrollMaster = lazy(() =>
  import("./pages/HRManagement/Payroll/PayrollMaster")
);
const TimeSheet = lazy(() => import("./pages/HRManagement/Payroll/TimeSheet"));
const InsuranceCompany = lazy(() =>
  import("./pages/HRManagement/Payroll/InsuranceCompany")
);
const InsuranceCompanyPlans = lazy(() =>
  import("./pages/HRManagement/Payroll/InsuranceCompanyPlans")
);
const GroupInsurance = lazy(() =>
  import("./pages/HRManagement/Payroll/GroupInsurance")
);
const AddGroupInsurance = lazy(() =>
  import("./pages/HRManagement/Payroll/AddGroupInsurance")
);
const ViewGroupInsurance = lazy(() =>
  import("./pages/HRManagement/Payroll/ViewGroupInsurance")
);
const SalaryDisbursal = lazy(() =>
  import("./pages/HRManagement/Payroll/SalaryDisbursal")
);
const ViewSalaryDisbursal = lazy(() =>
  import("./pages/HRManagement/Payroll/ViewSalaryDisbursal")
);
const PaySlip = lazy(() =>
  import("./pages/HRManagement/Payroll/PaySlip/PaySlip")
);
const ViewPaySlipDetails = lazy(() =>
  import("./pages/HRManagement/Payroll/PaySlip/ViewPaySlipDetails")
);
const ViewPaySlip = lazy(() => import("./components/ModalContent/ViewPaySlip"));
const Loan = lazy(() => import("./pages/HRManagement/Payroll/Loan"));
const EmployeePromotionDemotion = lazy(() =>
  import(
    "./pages/HRManagement/Payroll/EmployeePromotionDemotion/EmployeePromotionDemotion"
  )
);
const AddEmployeePromotionDemotion = lazy(() =>
  import(
    "./pages/HRManagement/Payroll/EmployeePromotionDemotion/AddEmployeePromotionDemotion"
  )
);
const EmployeeResignation = lazy(() =>
  import("./pages/HRManagement/Payroll/EmployeeResignation")
);
const EmployeeRetirement = lazy(() =>
  import("./pages/HRManagement/Payroll/EmployeeRetirement/EmployeeRetirement")
);
const AddEmployeeRetirement = lazy(() =>
  import(
    "./pages/HRManagement/Payroll/EmployeeRetirement/AddEmployeeRetirement"
  )
);
const EmployeeTracking = lazy(() =>
  import("./pages/HRManagement/Payroll/EmployeeTracking")
);
const EmployeeLogs = lazy(() =>
  import("./pages/HRManagement/Payroll/EmployeeLogs")
);
const EmployeeActivity = lazy(() =>
  import("./pages/HRManagement/Payroll/EmployeeActivity")
);
const FundManagement = lazy(() =>
  import("./pages/FundManagement/FundManagement")
);
const AddFunds = lazy(() => import("./pages/FundManagement/AddFunds"));
const FundRequest = lazy(() =>
  import("./pages/FundManagement/FundRequest/FundRequest")
);
const FundTransfer = lazy(() =>
  import("./pages/FundManagement/fund-transfer/FundTransfer")
);
const CreateFundRequest = lazy(() =>
  import("./pages/FundManagement/FundRequest/CreateFundRequest")
);
const ViewFundTransactions = lazy(() =>
  import("./pages/FundManagement/ViewFundTransactions")
);
const RequestCash = lazy(() =>
  import("./pages/FundManagement/DailyCash/RequestCash")
);
const CreateRequestCash = lazy(() =>
  import("./pages/FundManagement/DailyCash/CreateRequestCash")
);
const ExpensesCash = lazy(() =>
  import("./pages/FundManagement/DailyCash/ExpensesCash/ExpensesCash")
);
const CreateExpensesCash = lazy(() =>
  import("./pages/FundManagement/DailyCash/ExpensesCash/CreateExpensesCash")
);
const ExpenseCategory = lazy(() =>
  import("./pages/FundManagement/DailyCash/ExpenseCategory/ExpenseCategory")
);
const CreateExpenseCategory = lazy(() =>
  import(
    "./pages/FundManagement/DailyCash/ExpenseCategory/CreateExpenseCategory"
  )
);
const ViewRequestExpenses = lazy(() =>
  import("./pages/FundManagement/DailyCash/ViewRequestExpenses")
);
const Balance = lazy(() => import("./pages/FundManagement/DailyCash/Balance"));
const CompanyItemStock = lazy(() =>
  import("./pages/FundManagement/SiteItemsGoods/CompanyItemStock")
);
const AssignItems = lazy(() =>
  import("./pages/FundManagement/SiteItemsGoods/AssignItems")
);
const RequestItems = lazy(() =>
  import("./pages/FundManagement/SiteItemsGoods/RequestItems")
);
const CreateRequestItems = lazy(() =>
  import("./pages/FundManagement/SiteItemsGoods/CreateRequestItems")
);
const EditDashboard = lazy(() => import("./pages/EditDashboard"));
const BankManagement = lazy(() =>
  import("./pages/MasterDataManagement/BankManagement/BankManagement")
);
const CreateBankManagement = lazy(() =>
  import("./pages/MasterDataManagement/BankManagement/CreateBankManagement")
);
const MasterOutletManagement = lazy(() =>
  import("./pages/MasterDataManagement/MasterOutletManagement")
);
const MasterEnergyCompanyTeam = lazy(() =>
  import("./pages/MasterDataManagement/MasterEnergyCompanyTeam")
);
const MasterSupplierManagement = lazy(() =>
  import("./pages/MasterDataManagement/MasterSupplierManagement")
);
const MasterItemManagement = lazy(() =>
  import("./pages/MasterDataManagement/MasterItemManagement")
);
const TaxManagement = lazy(() =>
  import("./pages/MasterDataManagement/TaxManagement/TaxManagement")
);
const CreateTaxManagement = lazy(() =>
  import("./pages/MasterDataManagement/TaxManagement/CreateTaxManagement")
);
const BillNoFormat = lazy(() =>
  import("./pages/MasterDataManagement/BillNoFormat/BillNoFormat")
);
const CreateBillNoFormat = lazy(() =>
  import("./pages/MasterDataManagement/BillNoFormat/CreateBillNoFormat")
);
const PaymentMethod = lazy(() =>
  import("./pages/MasterDataManagement/PaymentMethod/PaymentMethod")
);
const CreatePaymentMethod = lazy(() =>
  import("./pages/MasterDataManagement/PaymentMethod/CreatePaymentMethod")
);

const CodeTesting = lazy(() => import("./pages/CodeTesting"));

const App = () => {
  const { user } = useSelector(selectUser);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  AOS.init();

  useEffect(() => {
    const selected = JSON.parse(localStorage.getItem("body-bg"));
    const bg = `rgba(${selected?.r || 233},${selected?.g || 233},${
      selected?.b || 240
    },${selected?.a || 1})`;
    document.documentElement.style.setProperty("--bs-indigo", bg);
  }, []);
  useEffect(() => {
    socket.on("connect", () => {
      // console.log(socket.id);
    });
    if (user) {
      socket.emit("newUser", { user_id: user.id });
    }
  }, [user]);

  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="vh-100 d-align">
              <img
                className="img-fluid"
                src="/assets/images/Curve-Loading.gif"
                alt="Loading"
              />
            </div>
          }
        >
          <Routes>
            <Route element={<PublicRoutes />}>
              <Route path="/" element={<SignIn />} />
            </Route>
            {/* <Route path="/" element={<Layout changeBg={changeBg} checked={color} />}> */}
            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Home />} />
              <Route path="/Analytics" element={<Analytics />} />
              <Route path="/MyCompanies" element={<MyCompanies />} />
              <Route path="/AllCompanies" element={<AllCompanies />} />
              <Route
                path="/MyCompanies/AddMyCompany/:id"
                element={<AddMyCompany />}
              />
              <Route
                path="/AllCompanies/AddAllCompany/:id"
                element={<AddAllCompanies />}
              />
              <Route
                path="/SaleCompanies/AddSaleCompanies/:id"
                element={<AddSaleCompanies />}
              />
              <Route
                path="/PurchaseCompanies/AddPurchaseCompanies/:id"
                element={<AddPurchaseCompanies />}
              />
              <Route
                path="/:ViewCompany/ViewCompany/:id"
                element={<ViewCompany />}
              />
              <Route
                path="/AllCompanies/ViewAllCompanies/:id"
                element={<ViewAllCompanies />}
              />
              <Route path="/SaleCompanies" element={<SaleCompanies />} />
              <Route
                path="/PurchaseCompanies"
                element={<PurchaseCompanies />}
              />

              <Route path="/order-via" element={<OrderVia />} />
              <Route
                path="/order-via/create-order-via/:id"
                element={<CreateOrderVia />}
              />
              <Route
                path="/create-complaint/:id"
                element={<CreateComplaint />}
              />
              <Route
                path="/RequestsComplaint"
                element={<RequestsComplaint />}
              />
              <Route
                path="/:title/ViewRequestsComplaint/:id"
                element={<ViewRequestsComplaint />}
              />
              <Route
                path="/ApprovedComplaints"
                element={<ApprovedComplaints />}
              />
              <Route
                path="/ApprovedComplaints/CreateAllocate/:id"
                element={<CreateAllocate />}
              />
              <Route
                path="/rejected-complaints"
                element={<RejectedComplaints />}
              />
              <Route
                path="/resolved-complaints"
                element={<ResolvedComplaints />}
              />
              <Route path="/Allocate" element={<Allocate />} />
              <Route path="/fund-request" element={<FundRequest />} />
              <Route path="/fund-transfer" element={<FundTransfer />} />
              <Route
                path="/fund-request/create-fund-request/:id"
                element={<CreateFundRequest />}
              />
              <Route path="/stock-request" element={<StockRequest />} />
              <Route
                path="/stock-request/create-stock-request/:id"
                element={<CreateStockRequest />}
              />
              <Route path="/stock-punch" element={<StockPunch />} />
              <Route
                path="/stock-punch/create-stock-punch/:id"
                element={<CreateStockPunch />}
              />
              <Route path="/expense-punch" element={<ExpensePunch />} />
              <Route
                path="/expense-punch/create-expense-punch/:id"
                element={<CreateExpensePunch />}
              />
              <Route path="/WorkQuotations" element={<WorkQuotations />} />
              <Route
                path="/WorkQuotations/CreateQuotation/:id"
                element={<CreateQuotation />}
              />
              <Route
                path="/WorkQuotations/send-work-quotation-by-email/:id"
                element={<SendWorkQuotationByEmail />}
              />
              <Route path="/PurchaseOrder" element={<PurchaseOrder />} />
              <Route
                path="/PurchaseOrder/view-details/:id"
                element={<PoViewDetails />}
              />
              <Route
                path="/PurchaseOrder/CreatePurchaseOrder/:id"
                element={<CreatePurchaseOrder />}
              />
              <Route
                path="/PurchaseOrder/create-security-deposit/:id"
                element={<CreateSecurityDeposit />}
              />
              <Route
                path="/PurchaseOrder/create-billing-type/:id"
                element={<CreateBillingType />}
              />
              <Route path="/Taxes" element={<Taxes />} />
              <Route path="/Taxes/create-taxes/:id" element={<CreateTaxes />} />
              <Route path="/financial-year" element={<FinancialYear />} />
              <Route
                path="/financial-year/create-financial-year/:id"
                element={<CreateFinancialYear />}
              />
              <Route
                path="/Measurements/CreateMeasurement/:id"
                element={<CreateMeasurement />}
              />
              <Route
                path="/Measurements/ViewMeasurement/:id"
                element={<ViewMeasurement />}
              />
              <Route path="/Measurements" element={<Measurements />} />
              <Route path="/Merge" element={<Merge />} />
              <Route path="/merged-pi" element={<MergedPi />} />
              <Route path="/merge-to-invoice" element={<MergetoInvoice />} />
              <Route
                path="/merge-to-invoice/Pi-to-merge/:id"
                element={<PiToMerge />}
              />
              <Route path="/Payments" element={<Payments />} />
              <Route
                path="/CreateNewPayments"
                element={<CreateNewPayments />}
              />
              <Route path="/PerformaInvoice" element={<PerformaInvoice />} />
              <Route
                path="/PerformaInvoice/CreatePerformaInvoice/:id"
                element={<CreatePerformaInvoice />}
              />
              <Route path="/Invoice" element={<Invoice />} />
              <Route
                path="/Invoice/CreateInvoice/:id"
                element={<CreateInvoice />}
              />
              <Route path="/Retention" element={<Retention />} />
              <Route path="/CreateRetention" element={<CreateRetention />} />
              <Route path="/product-category" element={<ProductCategory />} />
              <Route
                path="/product-category/create-product-category/:id"
                element={<CreateProductCategory />}
              />
              <Route path="/unit-data" element={<UnitData />} />
              <Route
                path="/unit-data/create-unit-data/:id"
                element={<CreateUnitData />}
              />
              <Route path="/ProductService" element={<ProductService />} />
              <Route
                path="/ProductService/AddProducts/:id"
                element={<AddProducts />}
              />
              <Route
                path="/DesignationPermissions"
                element={<DesignationPermissions />}
              />
              <Route path="/Teams" element={<Teams />} />
              <Route path="/Teams/create-teams/:id" element={<CreateTeams />} />
              <Route
                path="/Teams/view-team-level-wise/:id"
                element={<ViewTeamLevelWise />}
              />
              <Route
                path="/Teams/HrTeamMembers/:id"
                element={<HrTeamMembers />}
              />
              <Route path="/Employees" element={<Employees />} />
              <Route
                path="/Employees/ViewEmployee/:id"
                element={<ViewEmployee />}
              />
              <Route
                path="/Employees/AddEmployee/:id"
                element={<AddEmployee />}
              />
              <Route path="/Attendance" element={<Attendance />} />
              <Route
                path="/Attendance/UserAttendance/:id"
                element={<UserAttendance />}
              />
              <Route path="/Leaves" element={<Leaves />} />
              <Route path="/LeavesType" element={<LeavesType />} />
              <Route
                path="/ViewEmployeeLeave/:id"
                element={<ViewEmployeeLeave />}
              />
              <Route path="/Loan" element={<Loan />} />
              <Route path="/Payroll" element={<Payroll />} />
              <Route path="/PayrollMaster" element={<PayrollMaster />} />
              <Route path="/TimeSheet" element={<TimeSheet />} />
              <Route path="/TimeSheet" element={<TimeSheet />} />
              <Route path="/InsuranceCompany" element={<InsuranceCompany />} />
              <Route
                path="/InsuranceCompanyPlans"
                element={<InsuranceCompanyPlans />}
              />
              <Route path="/GroupInsurance" element={<GroupInsurance />} />
              <Route
                path="/GroupInsurance/AddGroupInsurance/:id"
                element={<AddGroupInsurance />}
              />
              <Route
                path="/GroupInsurance/ViewGroupInsurance/:id"
                element={<ViewGroupInsurance />}
              />
              <Route path="/SalaryDisbursal" element={<SalaryDisbursal />} />
              <Route
                path="/SalaryDisbursal/ViewSalaryDisbursal/:id/:month"
                element={<ViewSalaryDisbursal />}
              />
              <Route path="/PaySlip" element={<PaySlip />} />
              <Route
                path="/PaySlip/ViewPaySlipDetails/:id/:month"
                element={<ViewPaySlipDetails />}
              />
              <Route path="/ViewPaySlip" element={<ViewPaySlip />} />
              <Route
                path="/EmployeePromotionDemotion"
                element={<EmployeePromotionDemotion />}
              />
              <Route
                path="/EmployeePromotionDemotion/AddEmployeePromotionDemotion/:id"
                element={<AddEmployeePromotionDemotion />}
              />
              <Route
                path="/EmployeeResignation"
                element={<EmployeeResignation />}
              />
              <Route
                path="/EmployeeRetirement"
                element={<EmployeeRetirement />}
              />
              <Route
                path="/EmployeeRetirement/AddEmployeeRetirement/:id"
                element={<AddEmployeeRetirement />}
              />
              <Route path="/EmployeeTracking" element={<EmployeeTracking />} />
              <Route path="/EmployeeLogs" element={<EmployeeLogs />} />
              <Route
                path="/EmployeeLogs/EmployeeActivity/:id"
                element={<EmployeeActivity />}
              />
              <Route path="/FundManagement" element={<FundManagement />} />
              <Route path="/FundManagement/add-funds" element={<AddFunds />} />
              <Route
                path="/ViewFundTransactions"
                element={<ViewFundTransactions />}
              />
              <Route path="/RequestCash" element={<RequestCash />} />
              <Route path="/:id/:id/:id" element={<CreateRequestCash />} />
              <Route path="/ExpensesCash" element={<ExpensesCash />} />
              <Route
                path="/ExpensesCash/create-expenses-cash/:id"
                element={<CreateExpensesCash />}
              />
              <Route path="/expense-category" element={<ExpenseCategory />} />
              <Route
                path="/expense-category/create-expense-category/:id"
                element={<CreateExpenseCategory />}
              />
              <Route
                path="/ViewRequestExpenses"
                element={<ViewRequestExpenses />}
              />
              <Route path="/Balance" element={<Balance />} />
              <Route path="/CompanyItemStock" element={<CompanyItemStock />} />
              <Route path="/AssignItems" element={<AssignItems />} />
              <Route path="/RequestItems" element={<RequestItems />} />
              <Route
                path="/RequestItems/create-request-items/:id"
                element={<CreateRequestItems />}
              />
              <Route path="/EarthingTesting" element={<EarthingTesting />} />
              <Route
                path="/EarthingTesting/CreateEarthingTesting/:id"
                element={<CreateEarthingTesting />}
              />
              <Route path="/AllSurvey" element={<AllSurvey />} />
              <Route path="/SurveyItemMaster" element={<SurveyItemMaster />} />
              <Route
                path="/SurveyItemMaster/create-survey-item-master/:id"
                element={<CreateSurveyItemMaster />}
              />
              <Route path="/PurposeMaster" element={<PurposeMaster />} />
              <Route
                path="/PurposeMaster/create-purpose-master/:id"
                element={<CreatePurposeMaster />}
              />
              <Route
                path="/AllSurvey/CreateSurvey/:id"
                element={<CreateSurvey />}
              />
              <Route path="/ResponseSurvey" element={<ResponseSurvey />} />
              <Route
                path="/ResponseSurvey/ViewResponseSurvey/:id"
                element={<ViewResponseSurvey />}
              />
              <Route path="/AssignedSurvey" element={<AssignedSurvey />} />
              <Route path="/RequestSurvey" element={<RequestSurvey />} />
              <Route path="/item-stocks" element={<OfficeSiteStocks />} />
              <Route
                path="/item-stocks/complaints-on-outlet/:id"
                element={<ComplaintsOnOutlet />}
              />
              <Route
                path="/item-stocks/pending-complaints/:id"
                element={<PendingComplaints />}
              />
              <Route
                path="/item-stocks/view-pending-complaints/:id/:status"
                element={<ViewPendingComplaints />}
              />
              <Route path="/office-expense" element={<OfficeExpense />} />
              <Route
                path="/office-expense/food-expense-punch"
                element={<FoodExpensePunch />}
              />
              <Route
                path="/office-expense/office-complaints-on-outlet/:id"
                element={<OfficeComplaintsOnOutlet />}
              />
              <Route
                path="/office-expense/office-expense-pending-complaints/:id"
                element={<OfficeExpensePendingComplaints />}
              />
              <Route path="/site-expense" element={<SiteExpense />} />
              <Route path="/SiteStocks" element={<SiteStocks />} />
              <Route path="/WorkImages" element={<WorkImages />} />
              <Route
                path="/WorkImages/CreateWorkImages/:id"
                element={<CreateWorkImages />}
              />
              <Route
                path="/WorkImages/ViewWorkImages/:id"
                element={<ViewWorkImages />}
              />
              <Route path="/ItemMaster" element={<ItemMaster />} />
              <Route
                path="/ItemMaster/add-item-master/:id"
                element={<AddItemMaster />}
              />
              <Route path="/Stock" element={<Stock />} />
              <Route
                path="/Stock/stock-transfer/new"
                element={<StockTransfer />}
              />
              <Route path="/Stock/view-stock/:id" element={<ViewStock />} />
              <Route path="/AddStock" element={<AddStock />} />
              <Route path="/vendor" element={<Vendor />} />
              <Route path="/Suppliers" element={<Suppliers />} />
              <Route
                path="/Suppliers/AddSuppliers/:id"
                element={<AddSuppliers />}
              />
              <Route path="/AllAssets" element={<AllAssets />} />
              <Route
                path="/AllAssets/CreateAssets/:id"
                element={<CreateAssets />}
              />
              <Route path="/IdleAssets" element={<IdleAssets />} />
              <Route
                path="/assets-repair-require"
                element={<AssetsRepairRequire />}
              />
              <Route path="/AssignedAssets" element={<AssignedAssets />} />
              <Route
                path="/IndividualAssetProfile"
                element={<IndividualAssetProfile />}
              />
              <Route path="/AssetTimeline/:id" element={<AssetTimeline />} />
              <Route
                path="/AssignedAssets/timeline-assigned-assets/:id"
                element={<TimelineAssignedAssets />}
              />
              <Route path="/Contacts" element={<Contacts />} />
              <Route path="/Contacts" element={<Contacts />} />
              <Route
                path="/Contacts/CreateContacts/:id"
                element={<CreateContacts />}
              />
              <Route path="/DocumentCategory" element={<DocumentCategory />} />
              <Route
                path="/DocumentCategory/CreateDocumentCategory/:id"
                element={<CreateDocumentCategory />}
              />
              <Route path="/DocumentsLists" element={<DocumentsLists />} />
              <Route
                path="/DocumentCategory/DocumentCategoryView/:id"
                element={<DocumentCategoryView />}
              />
              <Route path="/AddDocument" element={<AddDocument />} />
              <Route path="/AddDocument/:id" element={<AddDocument />} />
              <Route path="/Communication" element={<Communication />} />
              <Route path="/Reports" element={<Reports />} />
              <Route
                path="/EnergyCompanyTeam"
                element={<EnergyCompanyTeam />}
              />
              <Route path="/TaskDashboard" element={<TaskDashboard />} />
              <Route path="/TaskCategory" element={<TaskCategory />} />
              <Route path="/AllTask" element={<AllTask />} />
              <Route path="/AllTask/TaskView/:id" element={<TaskView />} />
              <Route path="/OutletManagement" element={<OutletManagement />} />
              <Route
                path="/CreateOutletManagement"
                element={<CreateOutletManagement />}
              />
              <Route path="/MasterData" element={<MasterData />} />
              <Route
                path="/SuggestionsFeedbacks"
                element={<SuggestionsFeedbacks />}
              />
              <Route path="/Tutorials" element={<Tutorials />} />
              <Route path="/PlanPricing" element={<PlanPricing />} />
              <Route path="/MyProfile" element={<MyProfile />} />
              <Route path="/UserProfile" element={<UserProfile />} />
              <Route path="/AllNotifications" element={<AllNotifications />} />
              <Route path="/AllMessages" element={<AllMessages />} />
              <Route path="/EditDashboard" element={<EditDashboard />} />
              <Route path="/bank-management" element={<BankManagement />} />
              <Route
                path="/bank-management/create-bank-management/:id"
                element={<CreateBankManagement />}
              />
              <Route
                path="/MasterOutletManagement"
                element={<MasterOutletManagement />}
              />
              <Route
                path="/MasterEnergyCompanyTeam"
                element={<MasterEnergyCompanyTeam />}
              />
              <Route
                path="/MasterSupplierManagement"
                element={<MasterSupplierManagement />}
              />
              <Route
                path="/MasterItemManagement"
                element={<MasterItemManagement />}
              />
              <Route path="/CodeTesting" element={<CodeTesting />} />
              <Route path="/TaxManagement" element={<TaxManagement />} />
              <Route
                path="/TaxManagement/CreateTaxManagement/:id"
                element={<CreateTaxManagement />}
              />
              <Route path="/bill-no-format" element={<BillNoFormat />} />
              <Route
                path="/bill-no-format/create-bill-no-format/:id"
                element={<CreateBillNoFormat />}
              />
              <Route path="/payment-method" element={<PaymentMethod />} />
              <Route
                path="/payment-method/create-payment-method/:id"
                element={<CreatePaymentMethod />}
              />
            </Route>
            <Route path="/ViewSurvey/:id" element={<ViewSurvey />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
};
export default App;
