import {
  BsSpeedometer,
  BsBell,
  BsEmojiSmile,
  BsBarChart,
  BsCollectionPlay,
  BsListCheck,
  BsBuilding,
  BsTelephoneInbound,
  BsChatLeftQuote,
  BsCartCheck,
  BsReceiptCutoff,
  BsShopWindow,
  BsDiagram3,
  BsJoystick,
  BsCardChecklist,
  BsSearch,
  BsImages,
  BsUiChecks,
  BsCart,
  BsServer,
  BsReverseLayoutTextSidebarReverse,
  BsFiles,
  BsMegaphone,
  BsFileMedical,
  BsPeople,
  BsListTask,
  BsOutlet,
  BsPerson,
  BsPersonLinesFill,
  BsCashStack,
  BsHourglassSplit,
} from "react-icons/bs";

export const menubar = [
  {
    id: 1,
    nav: [
      {
        menu: "dashboard",
        url: "/",
        icon: <BsSpeedometer className="text-orange" />,
      },
    ],
  },
  {
    id: 2,
    drop: [
      {
        title: "Companies",
        nesicon: <BsBuilding className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "My Companies",
                url: "/MyCompanies",
              },
              {
                nestitle: "Sale Companies",
                url: "/SaleCompanies",
              },
              {
                nestitle: "Purchase Companies",
                url: "/PurchaseCompanies",
              },
              {
                nestitle: "All Companies",
                url: "/AllCompanies",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    drop: [
      {
        title: "Complaints",
        nesicon: <BsTelephoneInbound className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Order Via",
                url: "/order-via",
              },
              {
                nestitle: "Create Complaint",
                url: "/create-complaint/new",
              },
              {
                nestitle: "Requests Complaint",
                url: "/RequestsComplaint",
              },
              {
                nestitle: "Approved Complaints",
                url: "/ApprovedComplaints",
              },
              {
                nestitle: "Rejected Complaints",
                url: "/rejected-complaints",
              },
              {
                nestitle: "Resolved Complaints",
                url: "/resolved-complaints",
              },
              {
                nestitle: "Allocate",
                url: "/Allocate",
              },
              {
                nestitle: "Stock Request",
                url: "/stock-request",
              },
              {
                nestitle: "Stock Punch",
                url: "/stock-punch",
              },
              {
                nestitle: "Expense Punch",
                url: "/expense-punch",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    nav: [
      {
        menu: "Work Quotations",
        url: "/WorkQuotations",
        icon: <BsChatLeftQuote className="text-orange" />,
      },
    ],
  },
  {
    id: 5,
    nav: [
      {
        menu: "Purchase Order",
        url: "/PurchaseOrder",
        icon: <BsCartCheck className="text-orange" />,
      },
    ],
  },
  {
    id: 6,
    drop: [
      {
        title: "Billing",
        nesicon: <BsReceiptCutoff className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Taxes",
                url: "/Taxes",
              },
              {
                nestitle: "Financial Year",
                url: "/financial-year",
              },
              {
                nestitle: "Measurements",
                url: "/Measurements",
              },
              {
                nestitle: "Performa Invoice",
                url: "/PerformaInvoice",
              },
              {
                nestitle: "Merge to Invoice",
                url: "/merge-to-invoice",
              },
              {
                nestitle: "Merged Pi",
                url: "/merged-pi",
              },
              {
                nestitle: "Invoice",
                url: "/Invoice",
              },
              // {
              //   nestitle: "Merge",
              //   url: "/Merge",
              // },
              {
                nestitle: "Payments",
                url: "/Payments",
              },
              {
                nestitle: "Retention",
                url: "/Retention",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 7,
    drop: [
      {
        title: "Category / Product",
        nesicon: <BsShopWindow className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Category",
                url: "/product-category",
              },
              {
                nestitle: "Unit Data",
                url: "/unit-data",
              },
              {
                nestitle: "Product",
                url: "/ProductService",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 8,
    drop: [
      {
        title: "Fund Management",
        nesicon: <BsDiagram3 className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Fund Management",
                url: "/FundManagement",
              },
              {
                nestitle: "Fund Request",
                url: "/fund-request",
              },
              {
                nestitle: "Fund Transfer",
                url: "/fund-transfer",
              },
            ],
          },
          {
            submenu: [
              {
                id: 81,
                title: "Daily Cash",
                icon: <BsCashStack className="text-orange" />,
                className: "",
                smenu2: [
                  { title2: "Request Cash", url2: "/RequestCash" },
                  { title2: "Expense Category", url2: "/expense-category" },
                  { title2: "Expenses Cash", url2: "/ExpensesCash" },
                  {
                    title2: "View Request Expenses",
                    url2: "/ViewRequestExpenses",
                  },
                  { title2: "Balance", url2: "/Balance" },
                ],
              },
              {
                id: 82,
                title: "Site Items / Goods",
                icon: <BsHourglassSplit className="text-orange" />,
                className: "my-2",
                smenu2: [
                  { title2: "Request Items", url2: "/RequestItems" },
                  { title2: "Company Item Stock", url2: "/CompanyItemStock" },
                  { title2: "Assign Items", url2: "/AssignItems" },
                ],
              },
            ],
          },
          {
            smenu: [
              {
                nestitle: "View Fund Transactions",
                url: "/ViewFundTransactions",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 9,
    drop: [
      {
        title: "HR Management",
        nesicon: <BsPersonLinesFill className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              // {
              //     nestitle: 'Designation & Permissions',
              //     url: '/DesignationPermissions',
              // },
              {
                nestitle: "Teams",
                url: "/Teams",
              },
              {
                nestitle: "Employees",
                url: "/Employees",
              },
              {
                nestitle: "Attendance",
                url: "/Attendance",
              },
              {
                nestitle: "Leaves",
                url: "/Leaves",
              },
            ],
          },
          {
            submenu: [
              {
                id: 91,
                title: "Payroll",
                icon: <BsCashStack className="text-orange" />,
                smenu2: [
                  { title2: "Payroll", url2: "/Payroll" },
                  { title2: "Payroll Master", url2: "/PayrollMaster" },
                  // { title2: "Time-Sheet", url2: "/TimeSheet" },
                  { title2: "Group Insurance", url2: "/GroupInsurance" },
                  { title2: "Salary Disbursal", url2: "/SalaryDisbursal" },
                  { title2: "Loan", url2: "/Loan" },
                  { title2: "PaySlip", url2: "/PaySlip" },
                  {
                    title2: "Employee Promotion Demotion",
                    url2: "/EmployeePromotionDemotion",
                  },
                  {
                    title2: "Employee Resignation",
                    url2: "/EmployeeResignation",
                  },
                  {
                    title2: "Employee Retirement",
                    url2: "/EmployeeRetirement",
                  },
                  { title2: "Employee Tracking", url2: "/EmployeeTracking" },
                  { title2: "Employee Logs", url2: "/EmployeeLogs" },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 9,
    nav: [
      {
        menu: "Earthing Testing",
        url: "/EarthingTesting",
        icon: <BsJoystick className="text-orange" />,
      },
    ],
  },
  {
    id: 10,
    drop: [
      {
        title: "Survey",
        nesicon: <BsCardChecklist className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "All Survey",
                url: "/AllSurvey",
              },
              {
                nestitle: "Item Master",
                url: "/SurveyItemMaster",
              },
              {
                nestitle: "Purpose Master",
                url: "/PurposeMaster",
              },
              {
                nestitle: "Assigned Survey",
                url: "/AssignedSurvey",
              },
              {
                nestitle: "Request Survey",
                url: "/RequestSurvey",
              },
              {
                nestitle: "Response Survey",
                url: "/ResponseSurvey",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 11,
    drop: [
      {
        title: "Office Inspection",
        nesicon: <BsSearch className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Item Stock",
                url: "/item-stocks",
              },
              {
                nestitle: "Expense",
                url: "/office-expense",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 12,
    drop: [
      {
        title: "Site Inspection",
        nesicon: <BsSearch className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Site Inspection",
                url: "/SiteInspection",
              },
              {
                nestitle: "Expense",
                url: "/siteExpense",
              },
              {
                nestitle: "Stocks",
                url: "/siteStocks",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 13,
    nav: [
      {
        menu: "Work Images",
        url: "/WorkImages",
        icon: <BsImages className="text-orange" />,
      },
    ],
  },
  {
    id: 14,
    nav: [
      {
        menu: "Item Master",
        url: "/ItemMaster",
        icon: <BsUiChecks className="text-orange" />,
      },
    ],
  },
  {
    id: 15,
    nav: [
      {
        menu: "Stocks",
        url: "/Stock",
        icon: <BsBarChart className="text-orange" />,
      },
    ],
  },
  // {
  //   id: 125,
  //   nav: [
  //     {
  //       menu: "Vendor",
  //       url: "/vendor",
  //       icon: <BsCart className="text-orange" />,
  //     },
  //   ],
  // },
  {
    id: 16,
    nav: [
      {
        menu: "Suppliers",
        url: "/Suppliers",
        icon: <BsCart className="text-orange" />,
      },
    ],
  },
  {
    id: 17,
    drop: [
      {
        title: "Asset Management",
        nesicon: <BsServer className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "All Assets",
                url: "/AllAssets",
              },
              {
                nestitle: "Idle Assets",
                url: "/IdleAssets",
              },
              {
                nestitle: "Assets Repair Require",
                url: "/assets-repair-require",
              },
              {
                nestitle: "Assigned Assets",
                url: "/AssignedAssets",
              },
              // {
              //   nestitle: "Individual Asset Profile",
              //   url: "/IndividualAssetProfile",
              // },
              {
                nestitle: "Asset Timeline",
                url: "/AssetTimeline/new",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 18,
    nav: [
      {
        menu: "Contacts",
        url: "/Contacts",
        icon: <BsReverseLayoutTextSidebarReverse className="text-orange" />,
      },
    ],
  },
  {
    id: 19,
    drop: [
      {
        title: "Documents",
        nesicon: <BsFiles className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Document Category",
                url: "/DocumentCategory",
              },
              {
                nestitle: "Add Document",
                url: "/AddDocument",
              },
              {
                nestitle: "Documents List",
                url: "/DocumentsLists",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 20,
    nav: [
      {
        menu: "Communication",
        url: "/Communication",
        icon: <BsMegaphone className="text-orange" />,
      },
    ],
  },
  {
    id: 21,
    nav: [
      {
        menu: "Reports",
        url: "Reports",
        icon: <BsFileMedical className="text-orange" />,
      },
    ],
  },
  {
    id: 22,
    nav: [
      {
        menu: "Energy Company Team",
        url: "EnergyCompanyTeam",
        icon: <BsPeople className="text-orange" />,
      },
    ],
  },
  {
    id: 29,
    drop: [
      {
        title: "Task Manager",
        nesicon: <BsListTask className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Task Dashboard",
                url: "/TaskDashboard",
              },
              {
                nestitle: "Task Category",
                url: "/TaskCategory",
              },
              {
                nestitle: "All Task",
                url: "/AllTask",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 24,
    nav: [
      {
        menu: "Outlet Management",
        url: "OutletManagement",
        icon: <BsOutlet className="text-orange" />,
      },
    ],
  },
  {
    id: 25,
    nav: [
      {
        menu: "My Profile",
        url: "MyProfile",
        icon: <BsPerson className="text-orange" />,
      },
    ],
  },
  {
    id: 26,
    nav: [
      {
        menu: "Notifications",
        url: "/AllNotifications",
        icon: <BsBell className="text-orange" />,
      },
    ],
  },
  {
    id: 27,
    nav: [
      {
        menu: "Feedbacks",
        url: "/SuggestionsFeedbacks",
        icon: <BsEmojiSmile className="text-orange" />,
      },
    ],
  },
  {
    id: 28,
    nav: [
      {
        menu: "Tutorials",
        url: "Tutorials",
        icon: <BsCollectionPlay className="text-orange" />,
      },
    ],
  },
  {
    id: 29,
    nav: [
      {
        menu: "Plan & Pricing",
        url: "PlanPricing",
        icon: <BsListCheck className="text-orange" />,
      },
    ],
  },
  {
    id: 30,
    drop: [
      {
        title: "Master Data Manage",
        nesicon: <BsServer className="text-orange" />,
        dropmenu: [
          {
            smenu: [
              {
                nestitle: "Bank Management",
                url: "/bank-management",
              },
              {
                nestitle: "Tax Management",
                url: "/TaxManagement",
              },
              {
                nestitle: "Bill No Format",
                url: "/bill-no-format",
              },
              {
                nestitle: "Payment Method",
                url: "/payment-method",
              },
              {
                nestitle: "Outlet Management",
                url: "/MasterOutletManagement",
              },
              {
                nestitle: "Energy Company Team",
                url: "/MasterEnergyCompanyTeam",
              },
              {
                nestitle: "Supplier Management",
                url: "/MasterSupplierManagement",
              },
              {
                nestitle: "Item Management",
                url: "/MasterItemManagement",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const findActiveDropdownId = (menuArray, targetUrl) => {
  for (const menu of menuArray) {
    if (menu.nav && menu.nav[0].url === targetUrl) {
      return menu.id;
    }

    if (menu.drop) {
      for (const content of menu.drop) {
        for (const submenu of content.dropmenu) {
          if (Array.isArray(submenu.smenu)) {
            for (const smenuItem of submenu.smenu) {
              if (smenuItem.url === targetUrl) {
                return menu.id;
              }
            }
          }
          if (Array.isArray(submenu.submenu)) {
            for (const submenu2 of submenu.submenu) {
              if (Array.isArray(submenu2.smenu2)) {
                for (const smenu2Item of submenu2.smenu2) {
                  if (smenu2Item.url2 === targetUrl) {
                    return menu.id;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return null; // Return null if the URL is not found
};
export const findActiveSubDropdownId = (body, targetUrl) => {
  let menuid = null;
  body?.submenu?.map((item) => {
    item?.smenu2?.filter((sub) => {
      if (sub.url2 === targetUrl) {
        menuid = item.id;
      }
    });
  });
  return menuid;
};
